import React from "react";
import PropTypes from "prop-types";

import { Icon, Text } from "../../elements";

//styles
import {
	VerticalTabItemsNameWrapper,
	VerticalTabIconWrapper,
	VerticalTabLabelWrapper,
	TabLabelFormat,
} from "./styles";
const VerticalTabItem = ({
	variant,
	selectedTab,
	setSelectedTab,
	name,
	icon,
	onChange,
}) => {
	const isSelected = selectedTab === name; // 1. isSelected - defines tab is selected
	const tabLabelType = isSelected ? "selected" : "default"; // 2. type of label - defines label format based on tab type
	const tabLabelStyle = TabLabelFormat[variant][tabLabelType]; // 3. style of label - defines label style for variant/selected

	// tab change action
	const onTabChange = (name) => {
		setSelectedTab(name);
		onChange && onChange(name);
	};
	return (
		<VerticalTabItemsNameWrapper
			selected={isSelected}
			onClick={() => onTabChange(name)}
			variant={variant}
		>
			{variant === "withIcon" && isSelected && icon ? (
				<VerticalTabIconWrapper>
					<Icon className="list-item-icon" iconName={icon} size={"2rem"} />
				</VerticalTabIconWrapper>
			) : null}
			<VerticalTabLabelWrapper
				variant={variant}
				icon={icon}
				selected={isSelected}
			>
				<Text
					className={"tab-label"}
					variant={tabLabelStyle?.font}
					tag="h3"
					color={tabLabelStyle?.color}
					data-label={name}
				>
					{name}
				</Text>
			</VerticalTabLabelWrapper>
		</VerticalTabItemsNameWrapper>
	);
};
VerticalTabItem.propTypes = {
	name: PropTypes.string.isRequired, // defines name of the tab
	icon: function (props, propName, componentName) {
		if (props["variant"] === "withIcon" && !props[propName]) {
			return new Error(
				`${propName} is required when variant is withIcon in ${componentName}.`,
			);
		}
	}, // defines icon of the tab
	variant: PropTypes.oneOf(["default", "withIcon"]), // defines tab variant
	selectedTab: PropTypes.string, // defines selected tab
	onChange: PropTypes.func, // defines tab heading
};
export default VerticalTabItem;
